import { cancelOrder, cancelOrderWoo, createOrderWoo, deliverOrderWoo, getClientDetail, getOrderTicketWoo, payOrderWoo, restoreSingleOrderWoo, retryOrder, updatePrintStatus } from "./panel.service";
import { confirmWoo, infoWoo, showError, showSuccess } from "./shared/alert.service";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// Prepare body and set actions for single orders
export const setSingleOrderActionWoo = async (orderId, storeId, setIsLoading, updateTable, tabSelected, action) => {
    setIsLoading(true);
    let ordersArr = {
      ids: orderId,
    }
    let result = {};
    try{
      if(action==="fulfill"){
        result = await createOrderWoo(ordersArr)
      }
      if(action==="collect"){
        result = await deliverOrderWoo(ordersArr)
      }
      if(action==="cancel"){
        let response = await confirmWoo("¿Estás seguro?", "Una vez cambiado el estado no se podrá revertir");
        if (response.isConfirmed) {
          result = await cancelOrderWoo(orderId)
        }
      }
      if(action==="retry"){
        result = await retryOrder(orderId)
      }
      if(action==="restore"){
        result = await restoreSingleOrderWoo(orderId)
      }

      if(result?.ok){
        showSuccess(result?.ok?.message);
      }
      if(result?.fail){
        showError(result?.fail?.message);
      }

      if(result?.message){
        showSuccess(result?.message);
      }
      updateTable(tabSelected);
    } catch (error) {
      showError(error);
    }
    setIsLoading(false);
}

// Prepare body and set actions for multiple orders
export const setMassiveOrdersActionsWoo = async (ordersSelected, storeId, tabSelected, setIsLoading, updateTable, resetOrdersSelections, action) => {
    setIsLoading(true);
    let ordersArr = {
      ids: ordersSelected,
    }
    let result = {};
    try{
      if(action==="fulfill"){
        result = await createOrderWoo(ordersArr)
      }
      if(action==="collect"){
        result = await deliverOrderWoo(ordersArr)
      }
      if(action==="cancel"){
        result = await cancelOrder(ordersArr)
      }
      if(action==="retry"){
        result = await retryOrder(ordersArr)
      }

      if(result?.ok || result?.fail){
        let success = result?.ok ? result.ok.message : null;
        let error = result?.fail ? result.fail.message : null;
        infoWoo(success, error);
      }

      updateTable(tabSelected);
    } catch (error) {
      showError(error);
    }
    setIsLoading(false);
    resetOrdersSelections();
};

// --- Payment methhod integration ---
const createPaymentBtn = (preferenceId, classStyles, originalButton) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js';
  script.setAttribute('data-preference-id', preferenceId);
  return script
}

const observeAndClickMPButton = (container) => {
  const observer = new MutationObserver((mutationsList, observer) => {
    const mpBtn = container.querySelector('.mercadopago-button');
    if (mpBtn) {
      mpBtn.click();
      observer.disconnect();
    }
  });

  observer.observe(container, {
    childList: true,
    subtree: true,
  });
};

const setSingleOrderPayBtn = (order, preferenceId) => {
  const buttonsContainer = document.querySelector(`.grid-width-lg-${order[0]}`);
  const originalButton = buttonsContainer.firstChild;
  const newBtn = createPaymentBtn(preferenceId, "badge panel-format pay-deliver", originalButton)
  buttonsContainer.appendChild(newBtn);
  observeAndClickMPButton(buttonsContainer)
}

const setMassivePayBtn = (preferenceId) => {
  const massiveBtnsContainer = document.querySelector(".massive-btn-wrapper");
  const originalButton = massiveBtnsContainer.childNodes[2].firstChild;
  const newBtn = createPaymentBtn(preferenceId, "grid-fixed-size full", originalButton)
  massiveBtnsContainer.appendChild(newBtn);
  observeAndClickMPButton(massiveBtnsContainer)
}

export const getPreferenceId = async(rowIds, storeId, resetOrdersSelections) => {
  let ordersArr = {};
  if(typeof rowIds === "string"){
    ordersArr = { orders: [ rowIds ] }
  } else {
    ordersArr = { orders: [ ...rowIds ] }
  }
  let preferenceId;
  try {
    const response = await payOrderWoo(ordersArr)
    if(response){
      preferenceId = response.preferenceId;
    }
  } catch (error) {
    showError(error)
  }
  if(ordersArr.length !== 0 && ordersArr.length > 1){
    setMassivePayBtn(preferenceId)
  } else {
    setSingleOrderPayBtn(ordersArr.orders, preferenceId)
  }
  resetOrdersSelections();
}

export const printTickets = async (ordersToPrint, setIsLoading, storeId ) => {
  let ordersArr = {};
  if(typeof ordersToPrint === "string"){
    ordersArr = [ ordersToPrint ];
  } else {
    ordersArr =  [ ...ordersToPrint ];
  }
  setIsLoading && setIsLoading(true);
  try {
    await getOrderTicketWoo(ordersArr);
    setIsLoading &&  setIsLoading(false);
  } catch (error) {
    showError(error)
    setIsLoading &&  setIsLoading(false);
  }
}

// Set info for client modal
export const setClientInfo = async (orderId, storeId, ecommerce, setShowDetailsModal, setDetailsModalProps, tabSelected, updateTable) => {
  setShowDetailsModal(false);
  const result = await getClientDetail(orderId, storeId, ecommerce);
  if (!result.success) {
    showError(result.message);
  } else {
    setDetailsModalProps({
      ecommerce: ecommerce,
      orderId: orderId,
      storeId: storeId,
      tabSelected: tabSelected,
      updateTable: updateTable,
      handleClose: () => setShowDetailsModal(false),
      orderDetail: result.orderInfo,
    });
    setShowDetailsModal(true);
  }
};

// --- pagination ---
const customTotal = (from, to, size) => (
  <span className="pagination-total-msg">
    Mostrar
  </span>
);

export const options = (page, perPage, totalSize) => {
  return {
    prePageText: "<",
    nextPageText: ">",
    disablePageTitle: true,
    showTotal: true,
    page,
    sizePerPage: perPage,
    totalSize,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "40",
        value: 40,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  };
};

// --- formatters Woo ---
export const statusFormatterWoo = (cell, row) => {
  let myStyle = {
    cursor: "default",
    color: row.trackingColorStatusText,
    fontWeight: "900",
    fontSize: ".75rem"
  }
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={<Tooltip >{row.message}</Tooltip>}
    >
      <span
        style={myStyle}
      >
        {row.status}
      </span>
    </OverlayTrigger>
  );
};

export const numberFormatter = (cell, row) => {
  return <span>#{cell}</span>
}

export const packageAmountFormatter = (cell, row) => {
  return (
    <span>{cell}</span>
  )
}

export const guideFormatter = (cell, row) => {
  if(cell){
    let tooltipTxt = window.innerWidth<1025 ? `Seguir envío ${cell}` : "Seguir envío";
    let cellTxt = window.innerWidth<1025 ? `${cell.slice(0, 11)}...` : cell;
    if(row.trackingUrl){
      return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >{tooltipTxt}</Tooltip>}
      >
        <a href={row.trackingUrl} target="_blank" className="btn-link tracking-grid" rel="noreferrer">
          {cellTxt}
        </a>
      </OverlayTrigger>
      )
    } else {
      return <span>{cellTxt}</span>
    }
  } else {
    return <span>-</span>
  }
}

export const dateFormatter = (cell, row) => {
  const orderDate = new Date(row.date);
  return <span>{`${orderDate.getDate()}/${orderDate.getMonth()+1}/${orderDate.getFullYear()}`}</span>
}

// --- date filter ---
export const handleDateChangeWoo = async (dates, setStartDate, setEndDate, filterData) => {
  const [start, end] = dates;
  filterData(start, end);
  setStartDate(start);
  setEndDate(end);
};

// --- status filter ---
export const statusArgumentArray = ["Solicitud pendiente", "Pendiente de pago", "Pendiente de aprobación", "Pago rechazado", "Todos"];

export const filterByStatus = (dataArr, statusSelected) => {
  return dataArr.filter(obj => obj.status === statusSelected);
}

// --- columns structure ---
export const columns = (ecommerce, actionsFormatter, singleActionsFormatter, statusFilterFormatter) => {
  return(
    [
      {
        dataField: "actions",
        text: "ACCIONES",
        formatter: actionsFormatter,
        classes: () => {
          return "grid-width-sm";
        },
        headerClasses: () => {
          return "grid-width-sm";
        },
      },
      {
        dataField: "ecommerceNumber",
        text: `# ORDEN ${ecommerce.toUpperCase()}`,
        formatter: numberFormatter,
        headerClasses: () => {
          return "grid-width-sm-md";
        },
      },
      {
        dataField: "guideNumber",
        text: "NÚMERO DE SEGUIMIENTO",
        formatter: guideFormatter,
        headerClasses: () => {
          return "grid-width-lg";
        },
      },
      {
        dataField: "packageAmount",
        text: "CANT. BULTOS",
        formatter: packageAmountFormatter,
        headerClasses: () => {
          return "grid-width-sm";
        },
      },
      {
        dataField: "status",
        text: "STATUS PICKIT",
        formatter: statusFormatterWoo,
        classes: () => {
          return "grid-width-sm-md";
        },
        headerClasses: () => {
          return "grid-width-sm-md";
        },
        headerFormatter: statusFilterFormatter,
      },
      {
        dataField: "mode",
        text: "MODO",
        classes: () => {
          return "grid-width-sm";
        },
        headerClasses: () => {
          return "grid-width-sm";
        },
      },
      {
        dataField: "date",
        text: "FECHA",
        formatter: dateFormatter,
        sort: true,
        classes: () => {
          return "grid-width-sm";
        },
        headerClasses: () => {
          return "grid-width-sm";
        },
      },
      {
        dataField: "singleActions",
        text: "PAGO PENDIENTE",
        formatter: singleActionsFormatter,
        classes: (cell, row, rowIndex, colIndex) => {
          return `grid-width-lg-${row.id}`;
        },
        headerClasses: () => {
          return "grid-width-lg";
        },
      },
    ]
  )
}

export const noSelection = {
  mode: "checkbox",
  hideSelectColumn: true
}

export const onRowSelect = (row, isSelected, ordersSelected, setOrdersSelected, ordersForFulfillment, setOrdersForFulfillment, ordersForPayment, setOrdersForPayment, ordersForCollect, setOrdersForCollect, ordersPrint, setOrdersPrint, typeOfMerchant) => {
  if (row.buttons.showRegister) {
    let order = Array.from(ordersForFulfillment);
    if (isSelected) {
      order.push(
        row.id,
      );
    } else {
      const index = order.indexOf(row.id)
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersForFulfillment(order);
  }

  if (row.buttons.showPayment) {
    let order = Array.from(ordersForPayment);
    if (isSelected) {
      order.push(
        row.id,
      );
    } else {
      const index = order.indexOf(row.id)
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersForPayment(order);
  }

  if (row.buttons.showReadyToShip) {
    let order = Array.from(ordersForCollect);
    if (isSelected) {
      order.push(
        row.id,
      );
    } else {
      const index = order.indexOf(row.id)
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersForCollect(order);
  }

  if (row.buttons.showLabel) {
    let order = Array.from(ordersPrint);
    if (isSelected) {
      order.push(
        row.id,
      );
    } else {
      const index = order.indexOf(row.id)
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersPrint(order);
  }

  let order = Array.from(ordersSelected);
  if (isSelected) {
    order.push(
      row.id,
    );
  } else {
    const index = order.indexOf(row.id)
    if (index > -1) {
      order.splice(index, 1);
    }
  }
  setOrdersSelected(order);
}

export const onSelectAll = (isSelected, rows, setOrdersSelected, setOrdersForFulfillment, setOrdersForPayment, setOrdersForCollect, setOrdersPrint,  typeOfMerchant) => {
  let fulfillOrder = [];
  let payOrder = [];
  let collectOrder = [];
  let printOrder = [];
  let order = [];

  if (isSelected) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].buttons.showRegister) {
        fulfillOrder.push(
          rows[i].id,
        );
      }
      if (rows[i].buttons.showPayment) {
        payOrder.push(
          rows[i].id,
        );
      }
      if (rows[i].buttons.showReadyToShip) {
        collectOrder.push(
          rows[i].id,
        );
      }

      if (rows[i].buttons.showLabel) {
        printOrder.push(
          rows[i].id,
        );
      }
      order.push(
        rows[i].id,
      );
    }
  }

  if (!isSelected) {
    order = [];
    fulfillOrder = [];
    payOrder = [];
    printOrder = [];
    collectOrder = [];
  }
  setOrdersSelected(order);
  setOrdersForFulfillment(fulfillOrder);
  setOrdersForPayment(payOrder);
  setOrdersPrint(printOrder);
  setOrdersForCollect(collectOrder);
  return order;
}