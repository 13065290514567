import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { useAuthStore } from '../../store';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { motion } from 'framer-motion';
import {
  columns,
  filterByStatus,
  filterData,
  getPreferenceId,
  handleDateChange,
  noSelection,
  onRowSelect,
  onSelectAll,
  options,
  printTickets,
  setClientInfo,
  statusArgumentArray,
  validateForSingleAction,
} from '../../services/grid.service';
import Skeleton from 'react-loading-skeleton';
import { ButtonSmall } from '../units/Button';
import refresh from '../../assets/refresh.svg';
import search from '../../assets/search.svg';
import { FiEdit, FiPrinter, FiX, FiRotateCw } from 'react-icons/fi';
import { AiOutlineDollar } from 'react-icons/ai';
import { LuPackagePlus } from 'react-icons/lu';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { ModalEdit } from './ModalEdit';
import {
  setSingleOrderAction,
  setMassiveOrdersActions,
} from '../../services/grid.service';
import {
  childVariants,
  fadeInAndUpNoDelay,
} from '../../services/shared/animation.service';
import { StatusDropdownFilter } from './StatusDropdownFilter';
import { isLabelCreated } from '../../utils/status';
import EditOrderWooModal from './EditOrderWooModal';
import { patch } from '../../services/shared/rest.service';
import { getAmoutFixed } from '../../utils/format';
registerLocale('es', es);

export const Table = ({
  data,
  tabSelected,
  updateTable,
  ecommerce,
  originalLoading,
}) => {
  const auth = useAuthStore((state) => state.authData);
  const [filteredByDateList, setFilteredByDateList] = useState(data);
  const [isLoading, setIsLoading] = useState(originalLoading);
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [ordersForFulfillment, setOrdersForFulfillment] = useState([]);
  const [ordersForPayment, setOrdersForPayment] = useState([]);
  const [ordersForCollect, setOrdersForCollect] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsModalProps, setDetailsModalProps] = useState({});
  const [showEmptyMsg, setshowEmptyMsg] = useState(false);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPreferenceId, setisPreferenceId] = useState(false);
  const [currentPackageAmount, setCurrentPackageAmount] = useState(1);
  const [isEditOrderModalOpen, setIsEditOrderModalOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const refreshTable = () => {
    updateTable(auth?.user?.storeId, tabSelected);
    setOrdersForCollect([]);
    setOrdersForPayment([]);
    setOrdersForFulfillment([]);
  };

  const patchOrder = async (newPackageAmount) => {
    try {
      const result = await patch(
        `/panel/order/${currentOrderId}?shop=${auth?.user?.storeId}`,
        newPackageAmount
      );
      if (result) {
        refreshTable();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      setshowEmptyMsg(true);
    } else {
      setshowEmptyMsg(false);
    }
  }, [data, tabSelected]);

  useEffect(() => {
    if (isPreferenceId) {
      let isMpModalOpen = false;
      const interval = setInterval(() => {
        const element = document.getElementById('mercadopago-checkout');
        if (element) {
          isMpModalOpen = true;
        } else {
          isMpModalOpen = false;
        }
        if (!isMpModalOpen) {
          clearInterval(interval);
          setisPreferenceId(false);
          isMpModalOpen = false;
          setTimeout(() => {
            updateTable(auth?.user?.storeId, 'all');
          }, 500);
        } else {
          isMpModalOpen = true;
        }
      }, 500);
    }
  }, [isPreferenceId]);

  // --- row selection ---
  const selectRowModes = {
    mode: 'checkbox',
    clickToSelect: false,
    onSelect: (row, isSelected) =>
      onRowSelect(
        row,
        isSelected,
        ordersSelected,
        setOrdersSelected,
        ordersForFulfillment,
        setOrdersForFulfillment,
        ordersForPayment,
        setOrdersForPayment,
        ordersForCollect,
        setOrdersForCollect,
        auth.user.typeOfMerchant
      ),
    onSelectAll: (isSelected, rows) =>
      onSelectAll(
        isSelected,
        rows,
        setOrdersSelected,
        setOrdersForFulfillment,
        setOrdersForPayment,
        setOrdersForCollect,
        auth.user.typeOfMerchant
      ),
    bgColor: () => {
      return `#f6f6f6`;
    },
  };

  const resetOrdersSelections = () => {
    setOrdersSelected([]);
    setOrdersForFulfillment([]);
    setOrdersForPayment([]);
    setOrdersForCollect([]);
  };

  // -- column formatters --
  const actionsFormatter = (cell, row) => {
    if (row.overallTab === 'created') {
      return (
        <div className="actions-icons">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Editar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setClientInfo(
                  row.orderId,
                  auth?.user?.storeId,
                  ecommerce,
                  setShowDetailsModal,
                  setDetailsModalProps,
                  tabSelected,
                  updateTable
                )
              }
            >
              <FiEdit />
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Volver a realizar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setSingleOrderAction(
                  row.orderId,
                  auth?.user?.storeId,
                  setIsLoading,
                  updateTable,
                  tabSelected,
                  'retry'
                )
              }
            >
              <FiRotateCw />
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Imprimir etiqueta</Tooltip>}
          >
            <button
              type="button"
              className="btn position-relative"
              onClick={() => {
                printTickets(
                  row.orderId,
                  setIsLoading,
                  auth?.user?.storeId,
                  resetOrdersSelections
                );
              }}
            >
              <FiPrinter />
              {row.isNew && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                  NEW
                  <span className="visually-hidden">new tag</span>
                </span>
              )}
            </button>
          </OverlayTrigger>
        </div>
      );
    }
    if (row.overallTab === 'delivered') {
      return (
        <div className="actions-icons">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Editar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setClientInfo(
                  row.orderId,
                  auth?.user?.storeId,
                  ecommerce,
                  setShowDetailsModal,
                  setDetailsModalProps,
                  tabSelected,
                  updateTable
                )
              }
            >
              <FiEdit />
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Volver a realizar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setSingleOrderAction(
                  row.orderId,
                  auth?.user?.storeId,
                  setIsLoading,
                  updateTable,
                  tabSelected,
                  'retry'
                )
              }
            >
              <FiRotateCw />
            </button>
          </OverlayTrigger>
        </div>
      );
    }
    if (row.overallTab === 'issue' && row.originalStatus === 'error') {
      return (
        <div className="actions-icons">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Editar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setClientInfo(
                  row.orderId,
                  auth?.user?.storeId,
                  ecommerce,
                  setShowDetailsModal,
                  setDetailsModalProps,
                  tabSelected,
                  updateTable
                )
              }
            >
              <FiEdit />
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Volver a realizar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setSingleOrderAction(
                  row.orderId,
                  auth?.user?.storeId,
                  setIsLoading,
                  updateTable,
                  tabSelected,
                  'retry'
                )
              }
            >
              <FiRotateCw />
            </button>
          </OverlayTrigger>
        </div>
      );
    }
    if (row.overallTab === 'issue') {
      return (
        <div className="actions-icons">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Editar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setClientInfo(
                  row.orderId,
                  auth?.user?.storeId,
                  ecommerce,
                  setShowDetailsModal,
                  setDetailsModalProps,
                  tabSelected,
                  updateTable
                )
              }
            >
              <FiEdit />
            </button>
          </OverlayTrigger>
        </div>
      );
    }
    if (
      row.overallTab === 'pending' &&
      (row.originalStatus === 'pendingPayment' ||
        row.originalStatus === 'pendingPaymentApproval')
    ) {
      return (
        <div className="actions-icons">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Editar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setClientInfo(
                  row.orderId,
                  auth?.user?.storeId,
                  ecommerce,
                  setShowDetailsModal,
                  setDetailsModalProps,
                  tabSelected,
                  updateTable
                )
              }
            >
              <FiEdit />
            </button>
          </OverlayTrigger>
        </div>
      );
    }
    if (row.overallTab === 'pending') {
      return (
        <div className="actions-icons">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Editar</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setClientInfo(
                  row.orderId,
                  auth?.user?.storeId,
                  ecommerce,
                  setShowDetailsModal,
                  setDetailsModalProps,
                  tabSelected,
                  updateTable
                )
              }
            >
              <FiEdit />
            </button>
          </OverlayTrigger>
          {row.originalStatus === 'pending' && ecommerce === 'shopify' && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              overlay={<Tooltip>Editar Bultos</Tooltip>}
            >
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setCurrentPackageAmount(row.packageAmount);
                  setIsEditOrderModalOpen(true);
                  setCurrentOrderId(row.orderId);
                }}
              >
                <LuPackagePlus />
              </button>
            </OverlayTrigger>
          )}

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip>Cancelar envío</Tooltip>}
          >
            <button
              type="button"
              className="btn"
              onClick={() =>
                setSingleOrderAction(
                  row.orderId,
                  auth?.user?.storeId,
                  setIsLoading,
                  updateTable,
                  tabSelected,
                  'cancel'
                )
              }
            >
              <FiX />
            </button>
          </OverlayTrigger>

          {row.originalStatus === 'label created' && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              overlay={<Tooltip>Imprimir etiqueta</Tooltip>}
            >
              <button
                type="button"
                className="btn position-relative"
                onClick={() => {
                  printTickets(
                    row.orderId,
                    setIsLoading,
                    auth?.user?.storeId,
                    resetOrdersSelections
                  );
                }}
              >
                <FiPrinter />
                {row.isNew && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                    NEW
                    <span className="visually-hidden">new tag</span>
                  </span>
                )}
              </button>
            </OverlayTrigger>
          )}
          {row.originalStatus === 'waitingDropoff' && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 250 }}
              overlay={<Tooltip>Imprimir etiqueta</Tooltip>}
            >
              <button
                type="button"
                className="btn position-relative"
                onClick={() => {
                  printTickets(
                    row.orderId,
                    setIsLoading,
                    auth?.user?.storeId,
                    resetOrdersSelections
                  );
                }}
              >
                <FiPrinter />
                {row.isNew && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                    NEW
                    <span className="visually-hidden">new tag</span>
                  </span>
                )}
              </button>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 250 }}
            overlay={
              <Tooltip>
                Valor neto: {getAmoutFixed(row.shippingSubTotal)} <br />
                IVA: {getAmoutFixed(row.totalTaxIVA)} <br />
                Percepción IIBB: {getAmoutFixed(row.totalTaxIBB)} <br />
                Monto total a cobrar: {getAmoutFixed(row.shippingTotal)}
              </Tooltip>
            }
          >
            <button type="button" className="btn">
              <AiOutlineDollar />
            </button>
          </OverlayTrigger>
        </div>
      );
    }
  };

  const singleActionsFormatter = (cell, row, index) => {
    if (
      row.overallTab === 'pending' &&
      (row.originalStatus === 'pendingPayment' ||
        row.originalStatus === 'paymentRejected') &&
      validateForSingleAction('payment', auth.user.typeOfMerchant)
    ) {
      return (
        <button
          className="badge panel-format pay-deliver"
          onClick={() =>
            getPreferenceId(
              row.orderId,
              auth?.user?.storeId,
              setisPreferenceId,
              resetOrdersSelections,
              row?.preferenceId
            )
          }
        >
          Pagar etiqueta
        </button>
      );
    }
    if (
      row.overallTab === 'pending' &&
      isLabelCreated(row.originalStatus) &&
      validateForSingleAction('collect', auth.user.typeOfMerchant)
    ) {
      return (
        <button
          className="badge panel-format fulfill-deliver"
          onClick={() =>
            setSingleOrderAction(
              row.orderId,
              auth?.user?.storeId,
              setIsLoading,
              updateTable,
              tabSelected,
              'collect'
            )
          }
        >
          Solicitar colecta
        </button>
      );
    }
    if (
      row.overallTab === 'pending' &&
      row.originalStatus === 'pending' &&
      validateForSingleAction('fulfill', auth.user.typeOfMerchant)
    ) {
      return (
        <button
          className="badge panel-format fulfill-deliver"
          onClick={() =>
            setSingleOrderAction(
              row.orderId,
              auth?.user?.storeId,
              setIsLoading,
              updateTable,
              tabSelected,
              'fulfill'
            )
          }
        >
          Crear etiqueta
        </button>
      );
    }
    if (row.overallTab === 'issue' && row.originalStatus !== 'error') {
      return (
        <button
          className="badge panel-format restore-deliver"
          onClick={() =>
            setSingleOrderAction(
              row.orderId,
              auth?.user?.storeId,
              setIsLoading,
              updateTable,
              tabSelected,
              'restore'
            )
          }
        >
          Restaurar
        </button>
      );
    }
  };

  // -- status filter --
  const handleSelection = (statusChoice) => {
    if (statusChoice === 'Todos') {
      setFilteredByDateList(data);
    } else {
      setFilteredByDateList(filterByStatus(data, statusChoice));
    }
  };

  const statusFilterFormatter = (column, colIndex) => {
    if (
      auth.user.typeOfMerchant === 'prepay-dropoff' &&
      tabSelected === 'pending'
    ) {
      return (
        <DropdownButton
          id="dropdown-basic-button"
          title={column.text}
          as={ButtonGroup}
        >
          <StatusDropdownFilter
            tabSelected={tabSelected}
            statusArr={statusArgumentArray}
            handleSelection={handleSelection}
          />
        </DropdownButton>
      );
    } else {
      return column.text;
    }
  };

  // --- needed for enabeling search bar ---
  const { SearchBar } = Search;

  // --- table ---
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="orderId"
        data={filteredByDateList}
        columns={columns(
          ecommerce,
          actionsFormatter,
          singleActionsFormatter,
          statusFilterFormatter
        )}
        search
      >
        {(props) => (
          <motion.div
            id="fulltable"
            className="container-fluid"
            variants={fadeInAndUpNoDelay}
            initial="hidden"
            animate="visible"
          >
            {showEmptyMsg && (
              <div className="empty-msg">
                <p className="finish-small-title">
                  Aún no hay órdenes para mostrar
                </p>
              </div>
            )}
            <div className="d-flex flex-column justify-content-start h-100">
              <div className="topBar-container">
                <div className="search-container">
                  <SearchBar
                    {...props.searchProps}
                    className="custom-search-field"
                    placeholder="Busca #orden, guía, status o modo"
                    srText="Buscar"
                  />
                  <img src={search} alt="search" />
                </div>
                <DatePicker
                  locale="es"
                  selected={startDate}
                  onChange={(dates) =>
                    handleDateChange(dates, setStartDate, setEndDate)
                  }
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  onCalendarClose={() =>
                    filterData(data, startDate, endDate, setFilteredByDateList)
                  }
                  dateFormat="dd/MM/yy"
                  placeholderText="Filtrar por fecha"
                  className="custom-range-input"
                />
                {startDate !== null && (
                  <button
                    className="close-btn"
                    onClick={() => {
                      setEndDate(null);
                      setStartDate(null);
                      setFilteredByDateList(data);
                    }}
                  >
                    X
                  </button>
                )}
                {ordersSelected.length > 1 &&
                  (tabSelected !== 'all' || tabSelected !== 'issue') && (
                    <div className="massive-btn-wrapper">
                      {tabSelected === 'pending' &&
                        (auth.user.typeOfMerchant === 'postpay-collect' ||
                          auth.user.typeOfMerchant === 'postpay-dropoff') && (
                          <ButtonSmall
                            type="button"
                            btnTxt={`Crear etiqueta (${ordersForFulfillment.length})`}
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={() =>
                              setMassiveOrdersActions(
                                ordersForFulfillment,
                                auth?.user?.storeId,
                                tabSelected,
                                setIsLoading,
                                updateTable,
                                resetOrdersSelections,
                                'fulfill'
                              )
                            }
                            extraClass="grid-fixed-size full"
                          />
                        )}
                      {(auth.user.typeOfMerchant === 'postpay-collect' ||
                        auth.user.typeOfMerchant === 'prepay-collect' ||
                        auth.user.typeOfMerchant === 'prepay-both') &&
                        tabSelected === 'created' && (
                          <ButtonSmall
                            type="button"
                            btnTxt={`Solicitar colecta (${ordersForCollect.length})`}
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={() =>
                              setMassiveOrdersActions(
                                ordersForCollect,
                                auth?.user?.storeId,
                                tabSelected,
                                setIsLoading,
                                updateTable,
                                resetOrdersSelections,
                                'collect'
                              )
                            }
                            extraClass="grid-fixed-size full"
                          />
                        )}
                      {(auth.user.typeOfMerchant === 'prepay-collect' ||
                        auth.user.typeOfMerchant === 'prepay-both' ||
                        auth.user.typeOfMerchant === 'prepay-dropoff') &&
                        tabSelected === 'pending' && (
                          <ButtonSmall
                            type="button"
                            btnTxt={`Pagar (${ordersForPayment.length})`}
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={() =>
                              getPreferenceId(
                                ordersForPayment,
                                auth?.user?.storeId,
                                setisPreferenceId,
                                resetOrdersSelections
                              )
                            }
                            extraClass="grid-fixed-size full"
                          />
                        )}
                      {tabSelected !== 'pending' && (
                        <ButtonSmall
                          type="button"
                          btnTxt={`Imprimir (${ordersSelected.length})`}
                          showSpinner={false}
                          isDisabled={false}
                          onClickFn={() => {
                            printTickets(
                              ordersSelected,
                              setIsLoading,
                              auth?.user?.storeId,
                              resetOrdersSelections
                            );
                            setOrdersSelected([]);
                          }}
                          extraClass="grid-fixed-size outlined"
                        />
                      )}
                    </div>
                  )}
                <div className="update-btn-container">
                  <button
                    onClick={() => {
                      updateTable(auth?.user?.storeId, tabSelected);
                      setOrdersForCollect([]);
                      setOrdersForPayment([]);
                      setOrdersForFulfillment([]);
                    }}
                  >
                    <img src={refresh} alt="refresh" />
                  </button>
                </div>
              </div>
              {showDetailsModal && <ModalEdit {...detailsModalProps} />}
              <motion.div className="scrollTable" variants={childVariants}>
                <BootstrapTable
                  bordered={false}
                  headerClasses="custom-header-class"
                  rowClasses="custom-row-class"
                  bodyClasses="custom-body-class"
                  selectRow={
                    tabSelected === 'all' || tabSelected === 'issue'
                      ? noSelection
                      : selectRowModes
                  }
                  sort={{ dataField: 'date', order: 'desc' }}
                  {...paginationTableProps}
                  {...props.baseProps}
                />
              </motion.div>
            </div>
          </motion.div>
        )}
      </ToolkitProvider>
    </div>
  );
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton count={1} height={70} style={{ marginBottom: '10px' }} />
          <Skeleton count={1} height={30} style={{ marginBottom: '10px' }} />
          <Skeleton count={4} height={50} style={{ marginBottom: '10px' }} />
        </>
      ) : (
        <>
          <EditOrderWooModal
            isOpen={isEditOrderModalOpen}
            onClose={() => setIsEditOrderModalOpen(false)}
            onSave={(newPackageAmount) => patchOrder(newPackageAmount)}
            currentPackageAmount={currentPackageAmount}
          />
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </>
      )}
    </>
  );
};
